import { mapActions } from 'vuex';
import MapBox from "@comp/map/BaseMap.vue";
import mapDraw from "@comp/map/mapDraw/MapDraw";
//   使用功能变化记录及记录图502 总体格局变化记录及记录图402
export default {
    components: {
        MapBox,
        mapDraw,
    },
    data() {
        return {
            leftMapSize: 'map',
            rightMapSize: 'map',
            mapList: [],
            taskMap: null, //标会地图
            jsonStr: null,
            drawConf: {
                position: 'topleft',
                show: true,
                draw: {
                    polygon: true,
                    polyline: false,
                    rectangle: true
                }
            },
            left: null, //左侧地图
            right: null,//右侧地图
            leftGroup: null, //左侧地图 图层组
            rightGroup: null,//右侧地图 图层组
            isActive: null,
            boundData: window.mapFitBounds,
        };
    },
    created() {
        this.getGqyx();
    },
    mounted() {
        setTimeout(() => {
            switch (this.$router.currentRoute.query.ItemID) {
                case "402":
                    this.selectMap(this.formdata.dataDic.DBT, 'right');
                    break;
                case "502":
                    this.selectMap(this.formdata.dataDic.DBT, 'right');
                    this.selectMap(this.formdata.dataDic.JZT, 'left');
                    break
            }

        }, 500);
    },
    methods: {
        ...mapActions([
            'getYcsygnMap',
            "getVectorStyle",
        ]),
        // 清空图层组
        clearLayersGroup(group) {
            if (this.common.containGroup(group)) {
                group.clearLayers();
            }
        },
        // 查找 影像地图
        selectMap(value, which) {
            let group = null
            if (which == "left") {
                group = this.leftGroup
            } else {
                group = this.rightGroup
            }
            this.mapList.map((item) => {
                if (item.Mc == value) {
                    // 设置中心点和展示级别  
                    const latlng = (item.Zxd || window.mapCenter).split(",").map(ele => Number(ele));
                    const zoom = item.Zsjb || 13;
                    this[which].setView(latlng, zoom);
                    this.drawMap(item, group)
                }
            });
        },
        // 绘制地图
        async drawMap(info, group) {
            this.clearLayersGroup(group)
            let url = info.Url;
            let layer = info.Tcmc;
            if ((info.MapType == '0' || info.MapType == '1') && !layer) {
                this.common.showMsg("该影像图层不正确", "info")
                return;
            }
            switch (info.MapType) {
                case '0': // WMS 类型的地图 的展示，主要为天地图
                    let emap = new window.L.tileLayer(
                        `${url}?T=${layer}&x={x}&y={y}&l={z}&tk=${tk}`,
                        {
                            minZoom: 3,
                            maxZoom: 17,
                            crs: L.CRS.EPSG3857,
                            showName: "底图",
                            showType: "底图",
                            isBaseLayer: true
                        }
                    );
                    group.addLayer(emap);
                    break
                case '1':// WMTS 类型的地图 的展示
                    let ign = new window.L.tileLayer(
                        url,
                        {
                            crs: L.CRS.EPSG3857,
                            zIndex: 196,
                            maxZoom: 17,
                            showName: "底图",
                            showType: "底图",
                        }
                    );
                    window.L.layerGroup([ign]).addTo(group);
                    break
                case '2':// 矢量切片 的展示
                    let layerStyle = await this.getVectorStyle({
                        url: url
                    });
                    window.L.mapboxGL({
                        accessToken: window.MAPBOX_TOKEN,
                        style: layerStyle,
                        epsg: "EPSG:4490",
                    }).addTo(group);
                    this.dealVectorLayers();
                    break
            }
        },
        // 获取高清影响地址
        async getGqyx() {
            const itemCode = ({
                402: '15',
                502: '10',
            })[this.$router.currentRoute.query.ItemID];
            // {
            //     "CODE": "10",
            //     "NAME": "遗产使用功能图"
            //   },
            //   {
            //     "CODE": "11",
            //     "NAME": "病害分布图"
            //   },
            //   {
            //     "CODE": "13",
            //     "NAME": "遗产要素单体或局部的测绘图"
            //   },
            //   {
            //     "CODE": "15",
            //     "NAME": "卫星影像或航摄影像"
            //   },
            //   {
            //     "CODE": "20",
            //     "NAME": "保护区划图"
            //   },
            //   {
            //     "CODE": "303",
            //     "NAME": "使用功能基准图"
            //   },
            //   {
            //     "CODE": "5",
            //     "NAME": "遗产总图"
            //   },
            //   {
            //     "CODE": "6",
            //     "NAME": "遗产要素分布图"
            //   }
            const res = await this.getYcsygnMap({ itemCode });
            if (res && res.length > 0) {
                res.forEach(ele => { ele.Dtfbsj = ele.Dtfbsj && ele.Dtfbsj.substr(0, 7) });
                this.mapList = res;
            }
            else {
                this.mapList = [];
            }
        },
        // 标绘操作
        getJsonStr(JsonStr, event) {
            console.log("getJsonStr 参数", JsonStr, event);
            let jsonStr = JSON.parse(JsonStr);
            this.formdata.dataDic['jsonStr'] = jsonStr;
            if (this.operateMarker) {
                this.taskMap.removeLayer(this.operateMarker);
            }
            if (jsonStr.length === 0) return false;
            let typeList = ['draw:created', 'draw:editstop', 'draw:deleted']; // 创建、编辑、删除
            if (typeList.indexOf(event.type) > -1) {
                this.formdata.dataDic.GEOJSON = JsonStr;
            }
        },
        initMapLeft(map) {
            this.left = map;
            this.leftGroup = window.L.layerGroup().addTo(map); //左侧地图 图层组
            this.initMap([map, 'left']);
        },
        initMapRight(map) {
            this.right = map;
            this.taskMap = map;
            this.rightGroup = window.L.layerGroup().addTo(map)//右侧地图 图层组
            this.initMap([map, 'right']);
        },
        initMap(v) {
            let _this = this;
            let whichMap = v[1];
            this[whichMap] = v[0];
            let code = {
                left: 'right',
                right: 'left',
            };
            let map = this[whichMap];
            map.on('mousemove', (e) => {
                this.isActive = whichMap;
            });

            // 同步变化
            map.on('moveend', function (e) {
                if (this._container.id === ('mapidbox' + _this.isActive)) {
                    let newCenter = this.getCenter();
                    let newZoom = this.getZoom();
                    let newBound = this.getBounds();
                    let remotebounds = Object.values(newBound).map(value => ([value['lat'], value['lng']]));
                    _this[code[whichMap]].setView(newCenter, newZoom);
                }
            });
        },
    },
};