<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">总体格局变化记录及记录图</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
                @submit.native.prevent
            >
                <el-form-item :style="{ width: 'calc(100% + 200px)' }">
                    <div class="compare">
                        <div class="map-compare">
                            <div>
                                <MapBox
                                    ref="leftMap"
                                    mapid="left"
                                    :mapData="mapDataLeft"
                                    @initmap="initMapLeft"
                                />
                                <el-select
                                    v-model="formdata.dataDic.JZT"
                                    :disabled="true"
                                    class="date-selector left"
                                    @change="selectMap(arguments[0], 'left')"
                                >
                                    <el-option
                                        v-for="({ Mc, Dtfbsj },
                                        index) in mapList"
                                        :key="index"
                                        :label="Dtfbsj"
                                        :value="Dtfbsj"
                                    ></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="map-compare">
                            <div>
                                <MapBox
                                    ref="rightMap"
                                    mapid="right"
                                    :mapData="mapDataRight"
                                    @initmap="initMapRight"
                                />
                                <mapDraw
                                    :basicMap="right"
                                    :drawConf="
                                        isEdit ? drawConf : { show: false }
                                    "
                                    :jsonStr="formdata.dataDic.GEOJSON"
                                    @update:jsonStr="getJsonStr"
                                >
                                </mapDraw>
                                <el-select
                                    v-model="formdata.dataDic.DBT"
                                    :disabled="true"
                                    :class="[
                                        'date-selector',
                                        { left: !isEdit },
                                    ]"
                                    @change="selectMap(arguments[0], 'right')"
                                >
                                    <el-option
                                        v-for="({ Mc, Dtfbsj },
                                        index) in mapList"
                                        :key="index"
                                        :label="Dtfbsj"
                                        :value="Dtfbsj"
                                    ></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item
                    label="变化时间"
                    prop="BHSJ"
                    :style="{ width: '80%' }"
                >
                    <el-date-picker
                        v-model="formdata.dataDic.BHSJ"
                        type="date"
                        :disabled="!isEdit"
                        value-format="yyyy-MM-dd"
                        placeholder="请选择变化时间"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                    label="情况说明"
                    prop="QKSM"
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.QKSM"
                        :disabled="!isEdit"
                        placeholder="请填写情况说明"
                        type="textarea"
                        :autosize="{ minRows: 3 }"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="评估" prop="PG" :style="{ width: '80%' }">
                    <el-select
                        v-model="formdata.dataDic.PG"
                        :disabled="!isEdit"
                        placeholder="请选择评估"
                    >
                        <el-option
                            v-for="{ CODE, LABEL } in enumObj.PG"
                            :key="CODE"
                            :label="LABEL"
                            :value="CODE"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
import sygnbhjl from "../0_com_js/sygnbhjl.js";
export default {
    mixins: [sygnbhjl, infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                dataDic: {
                    BHSJ: null,
                    QKSM: null,
                    PG: null,
                    JZT: null,
                    DBT: null,
                    GeoJson: null,
                },
                fileids: "",
                itemCode: "402",
                metaJson: [],
            },
            rules: {
                BHSJ: [
                    {
                        required: true,
                        message: "请选择变化时间",
                        trigger: "blur",
                    },
                ],
                QKSM: [
                    {
                        required: true,
                        message: "请填写情况说明",
                        trigger: "blur",
                    },
                ],
                PG: [
                    { required: true, message: "请选择评估", trigger: "blur" },
                ],
                JZT: [
                    {
                        required: true,
                        message: "请输入对比基准图",
                        trigger: "blur",
                    },
                ],
                DBT: [
                    {
                        required: true,
                        message: "请输入对比图",
                        trigger: "blur",
                    },
                ],
            },
            mapDataLeft: {
                baseMapNum: 1,
                mapTypeBtn: false,
            },
            mapDataRight: {
                baseMapNum: 1,
                mapTypeBtn: false,
            },
        };
    },
    watch: {},
    methods: {
        ...mapActions(["GetDtList"]),
        GetDataNext() {
            if (this.formdata.dataDic.GEOJSON) {
                this.formdata.dataDic.GEOJSON = `[${this.formdata.dataDic.GEOJSON}]`;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
div.compare {
    div.map-compare {
        display: inline-block;
        width: 50%;
        padding-top: 30%;
        position: relative;
        > div {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            border: 2px solid rgb(167, 180, 152);
            margin: 1px;
            > div.date-selector {
                position: absolute;
                top: 10px;
                left: 50px;
                width: 120px;
                z-index: 999;
                &.left {
                    left: 10px;
                }
            }
        }
    }
}
</style>
